$(function () {
  const homeVideo = $('#home-video');

  if (homeVideo.is(':visible')) {
    $('source', homeVideo).each(
      function () {
        const el = $(this);
        el.attr('src', el.data('src'));
      }
    );

    homeVideo[0].load();
    homeVideo.addClass('video-loaded');
  }
});

// Resize video
function sizeTheVideo() {
  var aspectRatio = 1.78;
  var newheight2;
  var video = $('#popup-video-resize-js iframe, #vimeo-video iframe');
  var Windowwidth = $(window).width();
  var WindowHeight = $(window).height();
  if (Windowwidth < 900) {
    if (Windowwidth < WindowHeight) {
      if (Windowwidth < 600) {
        Windowwidth2 = Windowwidth;
        newheight2 = Windowwidth2 / aspectRatio;
      } else {
        Windowwidth2 = Windowwidth * 0.8;
        newheight2 = Windowwidth2 / aspectRatio;
      }
    } else {
      newheight2 = WindowHeight - 120;
      Windowwidth2 = newheight2 * aspectRatio;
    }
    video.css({ "width": Windowwidth2 + "px", "height": newheight2 + "px" });
    $(".home-registration-section").show();
  } else if (Windowwidth < 1100) {
    Windowwidth2 = Windowwidth * 0.8;
    newheight2 = Windowwidth2 / aspectRatio;
    video.css({ "width": Windowwidth2 + "px", "height": newheight2 + "px" });
    $(".home-registration-section").show();
  } else {
    videoHeight1 = 900 / aspectRatio;
    video.css({ "width": "900px", "height": videoHeight1 + "px" });
    $(".home-registration-section").show();
  }
}

$(window).resize(function () {
  sizeTheVideo();
});

// Function to get YouTube video title
function getYoutubeTitle(videoId) {
  const url = `https://www.googleapis.com/youtube/v3/videos?id=${videoId}&key=${g0876pyB.YTK}&part=snippet`;

  return $.get(url).then(response => {
    if (response.items && response.items.length > 0) {
      return response.items[0].snippet.title;
    }
    return null;
  });
}

// Function to get Vimeo video title
function getVimeoTitle(videoId) {
  const url = `https://api.vimeo.com/videos/${videoId}`;

  return $.ajax({
    url: url,
    type: 'GET',
    beforeSend: function (xhr) {
      xhr.setRequestHeader('Authorization', 'Bearer ' + g0876pyB.VAT);
    }
  }).then(response => {
    return response.name;
  });
}

// Get YouTube ID from URL
function getYoutubeId(url) {
  var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  var match = url.match(regExp);
  return (match && match[2].length === 11) ? match[2] : null;
}

// Get Vimeo ID from URL
function getVimeoId(url) {
  var regex = /https?:\/\/(?:www\.)?vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^\/]*)\/videos\/|album\/(?:\d+)\/video\/|)(\d+)/i;
  var match = url.match(regex);
  if (match && match[1]) {
    return match[1];
  } else {
    regex = /https?:\/\/(?:www\.)?vimeo\.com\/(?:\d+)\/(\d+)/i;
    match = url.match(regex);
    if (match && match[1]) {
      return match[1];
    } else {
      return null;
    }
  }
}

// Function to send YouTube time
function sendYTTime(time) {
  if (bobFormUser && bobFormUser.userInfo.email) {
    var marketo = {
      lastvideoviewtime: time
    };
    bobFormUser.sendMarketoData(marketo);
  }
}

var openVideoInFirstTime = true;
var openVimeoVideoInFirstTime = true;
var player = null;
var vimeoPlayer = null;
var videoId = '';
var send10sec = true;
var send20sec = true;
var send30sec = true;

window.onYouTubeIframeAPIReady = function () {
  document.dispatchEvent(new CustomEvent('onYouTubeIframeAPIReady', {}));
};

// Global variables
window.videoData = {
  videoName: '',
  videoPlatform: '',
  videoId: '',
  totalLength: 0,
  viewTime: 0,
  percentagePlayed: 0,
  intervalId: null
};

// Global functions for setting Vimeo and YouTube data
function setVimeoData(data) {
  window.videoData = data;
}

function setYoutubeData(data) {
  window.videoData = data;
}

// Function to send data to Marketo
function sendMarketoData(videoData) {
  if (bobFormUser && bobFormUser.userInfo.email) {
    var marketoData = {
      videoname: videoData.videoName,
      videoplatform: videoData.videoPlatform,
      videoid: videoData.videoId,
      totallength: videoData.totalLength,
      viewtime: videoData.viewTime,
      percentageplayed: videoData.percentagePlayed
    };
    console.log("Sending data to Marketo: ", marketoData);
    bobFormUser.sendMarketoData(marketoData);
  }
}

// Function to trigger Munchkin and send data to Marketo on modal close
function onVideoModalClose() {
  if (typeof Munchkin !== 'undefined') {
    Munchkin?.munchkinFunction('clickLink', {
      'href': '/video-closed',
      'params': window.videoData
    });
  }
  // Push data to data layer for GTM
  window.dataLayer?.push({
    event: 'videoModalClosed',
    videoName: window.videoData.videoName,
    videoPlatform: window.videoData.videoPlatform,
    videoId: window.videoData.videoId,
    totalLength: window.videoData.totalLength,
    viewTime: window.videoData.viewTime,
    percentagePlayed: window.videoData.percentagePlayed
  });
  sendMarketoData(window.videoData);
}

function handleVideoTimeUpdate(player, videoData, interval) {
  let intervalId = setInterval(async () => {
    let currentTime = await (player.getCurrentTime ? player.getCurrentTime() : player.currentTime);
    currentTime = Math.floor(currentTime);
    videoData.viewTime = currentTime;
    videoData.percentagePlayed = Math.floor((currentTime / videoData.totalLength) * 100);
  }, interval);

  return intervalId;
}

// Function to handle video title assignment
function assignVideoTitle(videoId, platform) {
  let getTitleFunction;

  if (platform === 'youtube') {
    getTitleFunction = getYoutubeTitle;
  } else if (platform === 'vimeo') {
    getTitleFunction = getVimeoTitle;
  }

  if (getTitleFunction) {
    getTitleFunction(videoId).then(title => {
      window.videoData.videoName = title;
    });
  }
}

// Ensure the YouTube IFrame API is loaded
var tag = document.createElement('script');
tag.src = "https://www.youtube.com/iframe_api";
var firstScriptTag = document.getElementsByTagName('script')[0];
firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

document.addEventListener('onYouTubeIframeAPIReady', function (e) {
}, false);

function createYouTubePlayer(videoId) {
  var playerVars = {
    videoId: videoId,
    playerVars: {
      'autoplay': 1,
      'rel': 0,
      'cc_load_policy': 1
    },
    events: {
      'onReady': onPlayerReady,
      'onStateChange': onPlayerStateChange
    }
  };

  if (UserHasCookieConsent('targeting') != true) {
    playerVars.host = 'https://www.youtube-nocookie.com';
  }

  player = new YT.Player('iframe-youtube', playerVars);
}

function onPlayerReady(event) {
  sizeTheVideo();

  try {
    const duration = Math.floor(player.getDuration());
    window.videoData.totalLength = duration;
    window.videoData.intervalId = handleVideoTimeUpdate(player, window.videoData, 1000);
  } catch (error) {
    console.error('Error getting video duration:', error);
  }
}


function onPlayerStateChange(event) {
  if (event.data == YT.PlayerState.PAUSED) {
    clearInterval(window.videoData.intervalId);
  } else if (event.data == YT.PlayerState.PLAYING) {
    window.videoData.intervalId = handleVideoTimeUpdate(player, window.videoData, 1000);
  } else if (event.data == YT.PlayerState.ENDED) {
    onVideoModalClose();
  }
}

function initializeYouTubePlayer(videoId) {
  if (player && typeof player.loadVideoById === 'function') {
    player.loadVideoById(videoId);
    player.getIframe().style.display = 'block';
    player.playVideo();
  } else {
    createYouTubePlayer(videoId);
  }
}

$("#video-modal").on("show.bs.modal", function () {
  var video_src = $("#video-modal").find('.modal-body').attr('data-video-url');
  var oldVideoID = videoId;
  videoId = getYoutubeId(video_src);

  if (videoId) {
    window.videoData.videoPlatform = 'youtube';
    window.videoData.videoId = videoId;
    assignVideoTitle(videoId, 'youtube');

    if (typeof YT == 'undefined' || openVideoInFirstTime == true) {
      openVideoInFirstTime = false;
      if (typeof YT == 'undefined') {
        // Load the YouTube IFrame API script
        var tag = document.createElement('script');
        tag.src = "https://www.youtube.com/iframe_api";
        var firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

        document.addEventListener('onYouTubeIframeAPIReady', function (e) {
          createYouTubePlayer(videoId);
        }, false);
      } else {
        createYouTubePlayer(videoId);
      }
    } else if (oldVideoID != videoId) {
      send10sec = true;
      send20sec = true;
      send30sec = true;
      initializeYouTubePlayer(videoId);
    } else {
      if (player) {
        player.getIframe().style.display = 'block';
        player.playVideo();
      } else {
        createYouTubePlayer(videoId);
      }
    }
  } else {
    videoURL = video_src;
    videoId = getVimeoId(video_src);
    if (videoId) {
      if (videoId == '933228755' && bobFormUser.countryData.code == 'GB') {
        videoId = 953185644;
        videoURL = "https://vimeo.com/953185644";
      }
      window.videoData.videoPlatform = 'vimeo';
      window.videoData.videoId = videoId;
      assignVideoTitle(videoId, 'vimeo');

      if (typeof Vimeo === 'undefined' || openVimeoVideoInFirstTime == true) {
        openVimeoVideoInFirstTime = false;
        if (typeof Vimeo == 'undefined') {
          let vimeoApiScript = document.createElement('script');
          vimeoApiScript.src = 'https://player.vimeo.com/api/player.js';
          document.body.appendChild(vimeoApiScript);
        }
        let checkVimeoLoaded = setInterval(function () {
          if (typeof Vimeo !== 'undefined') {
            clearInterval(checkVimeoLoaded);

            var vimeoPlayerVars = {
              url: videoURL,
              autoplay: true,
              loop: false
            };

            if (UserHasCookieConsent('targeting') != true) {
              vimeoPlayerVars.dnt = true;
            }

            vimeoPlayer = new Vimeo.Player('vimeo-video', {
              ...vimeoPlayerVars
            });

            vimeoPlayer.on('loaded', function () {
              vimeoPlayer.getDuration().then(duration => {
                window.videoData.totalLength = Math.floor(duration);
                window.videoData.intervalId = handleVideoTimeUpdate(vimeoPlayer, window.videoData, 1000);
              });
            });


            vimeoPlayer.on('play', function () {
              window.videoData.intervalId = handleVideoTimeUpdate(vimeoPlayer, window.videoData, 1000);
            });

            vimeoPlayer.on('pause', function () {
              clearInterval(window.videoData.intervalId);
            });
            vimeoPlayer.on('ended', function () {
              sendMarketoData(window.videoData);
            });
          }
        }, 100);
      } else if (oldVideoID != videoId) {
        vimeoPlayer.loadVideo(videoId).then(function (id) {
          vimeoPlayer.element.style.display = 'block';
          vimeoPlayer.play();
        }).catch(function(error) {
          console.error(error, 'error loading vimeo video');
        });
      } else {
        vimeoPlayer.element.style.display = 'block';
        vimeoPlayer.play();
      }
    }
  }
  if ((typeof ga === 'function' && ga.loaded))
    ga('send', 'event', 'Video', 'Watch', videoId);
});

$("#video-modal").on("hide.bs.modal", function () {
  if (player != null) {
    player.pauseVideo();
    player.getIframe().style.display = 'none';
    clearInterval(window.videoData.intervalId);
  }
  if (vimeoPlayer != null) {
    vimeoPlayer.pause();
    vimeoPlayer.element.style.display = 'none';
    clearInterval(window.videoData.intervalId);
  }

  onVideoModalClose();
});

$('[data-target="#book-demo-modal"]').each(function () {
  $(this).on('click', function () {
    if (typeof Munchkin != 'undefined')
      Munchkin.munchkinFunction('clickLink', {
        'href': '/request-a-demo-modal'
      }
      );
  });
});
$('[data-target="#watch-modal"]').each(function () {
  $(this).on('click', function () {
    if (typeof Munchkin != 'undefined')
      Munchkin.munchkinFunction('clickLink', {
        'href': '/watch-a-demo-modal'
      }
      );
  });
});
$('[data-video-src]').each(function () {
  $(this).on('click', function (e) {
    e.preventDefault();
    $('#video-modal .modal-body').attr('data-video-url', $(this).attr('data-video-src'));
    $("#video-modal").modal('show');
    var youtubeId = getYoutubeId($(this).attr('data-video-src'));
    if (typeof Munchkin != 'undefined')
      Munchkin.munchkinFunction('clickLink', {
        'href': '/play-video-' + youtubeId
      }
      );
  });
});
$('[data-target="#video-modal"]').each(function () {
  $(this).on('click', function () {
    var youtubeId = getYoutubeId($('#video-modal').find('[data-video-url]').attr('data-video-url'));
    if (typeof Munchkin != 'undefined')
      Munchkin.munchkinFunction('clickLink', {
        'href': '/play-video-' + youtubeId
      }
      );
  });
});
$('.trd-ph-embedded').on('click', function () {
  var videoUrl = $(this).find('a[data-page-type="video"]').attr('href');
  if (videoUrl) {
    var youtubeId = getYoutubeId(videoUrl);
    if (typeof Munchkin != 'undefined')
      Munchkin.munchkinFunction('clickLink', {
        'href': '/play-trendemon-video-' + youtubeId
      }
      );
  }
});

// New code to detect tab visibility changes
document.addEventListener('visibilitychange', function () {
  if ($("#video-modal").is(":visible")) {
    if (document.visibilityState === 'hidden') {
      // User has switched to a different tab or minimized the browser
      if (player) {
        player.pauseVideo();
        clearInterval(window.videoData.intervalId);
      }
      if (vimeoPlayer) {
        vimeoPlayer.pause();
        clearInterval(window.videoData.intervalId);
      }

      sendMarketoData(window.videoData); // Send data to Marketo when the user leaves the tab
      onVideoModalClose(); // Trigger modal close actions
    } else if (document.visibilityState === 'visible') {
      // User has returned to the tab
      if (player) {
        player.playVideo();
        window.videoData.intervalId = handleVideoTimeUpdate(player, window.videoData, 1000);
      }
      if (vimeoPlayer) {
        vimeoPlayer.play();
        window.videoData.intervalId = handleVideoTimeUpdate(vimeoPlayer, window.videoData, 1000);
      }
    }
  }
});