const focusableElementsSelector = 'a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input:not([type="hidden"], [disabled]), select:not([disabled])';

/**
 * Set HTML attribute of "inert" to any sibilings of a given element,
 * Exceptions: no script/style tags and no hidden elements (modals, popups...)
 *
 * @param element
 * @param enable true/false
 */
function trapFocusNative(element, enable = true) {
    [...element.parentNode?.children].filter((child) => {
      return (
        child !== element // not the same element
        && !['script', 'style'].includes(child.nodeName.toLowerCase()) // not script or style in the document body
        && child.offsetParent !== null // only visible elements (skip modals or any other hidden element)
      );
    }).forEach(sibling => sibling.inert = enable);
}

/**
 * Apply a simple focus trap with JS Keydown event.
 *
 * @param element
 */
function trapFocus(element) {
    if (!element) {
        return;
    }

    const focusableEls = element.querySelectorAll(focusableElementsSelector);
    const firstFocusableEl = focusableEls[0];
    const lastFocusableEl = focusableEls[focusableEls.length - 1];
    const KEYCODE_TAB = 9;

    element.addEventListener('keydown', function (e) {
        const isTabPressed = (e.key === 'Tab' || e.keyCode === KEYCODE_TAB);

        if (!isTabPressed) {
            return;
        }

        if (e.shiftKey) /* shift + tab */ {
            if (document.activeElement === firstFocusableEl) {
                lastFocusableEl.focus();
                e.preventDefault();
            }
        } else /* tab */ {
            if (document.activeElement === lastFocusableEl) {
                firstFocusableEl.focus();
                e.preventDefault();
            }
        }
    });
}